import {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import * as actions from "../../ducks/auth";
import { useAppDispatch } from "../../redux-store";
import { useTranslation } from "react-i18next";
import { initialize } from "../../ducks/features";
import { isMobile } from "../../helpers/mobile";
import {
  ENVIRONMENT_PROD,
  ENVIRONMENT_STAGING,
  getEnvironment,
  setApiEnvironment,
  setOrganizationRef,
} from "../../helpers/apiRequest";
import {
  addOrganization,
  getOrganizations,
} from "../../helpers/organizationStorage";
import Autocomplete from "@mui/material/Autocomplete";
import { Device } from "@capacitor/device";
import { changeLanguage, Languages } from "../../i18n/i18n";

const NEW_ORGANIZATION_OPTION = {
  reference: "NEW_ORGANIZATION_OPTION",
  name: "Add New",
};

export default function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation("authPage");
  const [searchParams] = useSearchParams();
  const [organization, setOrganization] = useState(
    NEW_ORGANIZATION_OPTION.reference
  );
  const [newOrganization, setNewOrganization] = useState("");
  const [number, setNumber] = useState("");
  const [loginError, setLoginError] = useState<
    null | "AUTH_ERROR" | "SERVER_ERROR"
  >(null);
  const [pin, setPin] = useState("");
  const orgId = searchParams.get("org") || undefined;

  const changeNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setNumber(e.target.value);
  };
  const changePin = (e: ChangeEvent<HTMLInputElement>) => {
    setPin(e.target.value);
  };

  useEffect(() => {
    if (isMobile) {
      Device.getLanguageCode().then((code) => {
        changeLanguage(code.value as keyof typeof Languages);
      });
    }
  }, []);

  const seenOrganizations = useMemo(() => {
    return [...getOrganizations(), NEW_ORGANIZATION_OPTION];
  }, []);

  useEffect(() => {
    // Check if the organization is in the URL and we have seen it before
    const domain = window.location.host.split(".");
    if (domain[1] === "m") {
      const orgRef = domain[0];
      const seenOrg = seenOrganizations.find((org) => org.reference === orgRef);
      if (seenOrg) {
        setOrganization(seenOrg.reference);
      } else {
        setOrganization(seenOrganizations[0].reference);
      }
    } else {
      // Clear the organization ref
      setOrganizationRef(undefined);
      setOrganization(seenOrganizations[0].reference);
    }
  }, [seenOrganizations]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let organizationRef =
      seenOrganizations.find((org) => org.reference === organization)
        ?.reference ?? NEW_ORGANIZATION_OPTION.reference;

    if (organizationRef === NEW_ORGANIZATION_OPTION.reference) {
      organizationRef = newOrganization;
    }

    setOrganizationRef(organizationRef);
    dispatch(actions.login({ orgId, number, pin }))
      .unwrap()
      .then(
        (result) => {
          if (result) {
            // Save the organization to local storage
            addOrganization({
              name: result.organisationName,
              reference: organizationRef,
            });

            const state = location.state as any;
            dispatch(initialize());
            if (state && state?.returnPath) {
              navigate({
                pathname: state && state?.returnPath,
              });
            } else {
              navigate("/routes/login");
            }
          } else {
            setLoginError("AUTH_ERROR");
          }
        },
        () => setLoginError("SERVER_ERROR")
      );
  };

  const [environment, setEnvironment] = useState(getEnvironment());
  const changeEnvTimeout = useRef<NodeJS.Timeout | null>(null);
  const onMouseDown = () => {
    if (!isMobile) {
      return;
    }
    changeEnvTimeout.current = setTimeout(() => {
      const newEnvironment =
        environment === ENVIRONMENT_STAGING
          ? ENVIRONMENT_PROD
          : ENVIRONMENT_STAGING;
      setApiEnvironment(newEnvironment);
      setEnvironment(newEnvironment);
    }, 10000);
  };

  const onMouseUp = () => {
    if (changeEnvTimeout.current) {
      clearTimeout(changeEnvTimeout.current);
      changeEnvTimeout.current = null;
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        bgcolor:
          environment === ENVIRONMENT_STAGING ? "var(--warning-200)" : "none",
      }}
    >
      <Box
        mt={14}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div onPointerDown={onMouseDown} onPointerUp={onMouseUp}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} />
        </div>
        <Typography component="h1" variant="h5">
          {t("login.heading")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {isMobile && (
            <>
              <Autocomplete
                disablePortal
                fullWidth
                options={seenOrganizations}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    required
                    fullWidth
                    id="organization"
                    label={t("login.textFieldLabel_ORGANIZATION")}
                    name="organization"
                  />
                )}
                value={seenOrganizations.find(
                  (org) => org.reference === organization
                )}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : option
                }
                onChange={(e, value) => {
                  setOrganization(value.reference);
                }}
              />
              {organization === NEW_ORGANIZATION_OPTION.reference && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="newOrganization"
                  label={t("login.textFieldLabel_NEW_ORGANIZATION")}
                  name="newOrganization"
                  value={newOrganization}
                  onChange={(e) => setNewOrganization(e.target.value)}
                />
              )}
            </>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 4,
            }}
            id="drivers_number"
            label={t("login.textFieldLabel_DRIVER")}
            name="drivers_number"
            autoComplete="drivers_number"
            value={number}
            onChange={changeNumber}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 4,
            }}
            name="password"
            label={t("login.textFieldLabel_PIN")}
            value={pin}
            onChange={changePin}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {loginError && (
            <Alert severity="error">
              {loginError === "AUTH_ERROR" ? (
                <span>{t("login.errorMessage")}</span>
              ) : (
                <span>{t("login.errorMessage_GENERAL")}</span>
              )}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("login.loginButtonLabel")}
          </Button>
        </Box>
      </Box>
      {environment === ENVIRONMENT_STAGING && (
        <Box sx={{ p: 2 }}>
          <Typography>{t("login.stagingWarning")}</Typography>
        </Box>
      )}
    </Container>
  );
}
